<script>
// Extensions
import View from "@/views/View";

// Mixins
import LoadSections from "@/mixins/load-sections";

export default {
  name: "Security",
  metaInfo: {
    title: "Security",
    titleTemplate: "%s | Tentifly - Smart sales | booking assistant"
  },
  extends: View,
  mixins: [LoadSections(["security"])],
  props: {
    id: {
      type: String,
      default: "about"
    }
  }
};
</script>
